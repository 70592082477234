import React from 'react';
import { useTranslation } from 'react-i18next';

const ExchangeSection = () => {
  const { t } = useTranslation();

  return (
    <section className="exchange">
      <div className="exchange__background exchange__background--pattern">
        <img className="exchange__pattern-bg" src="../assets/media/images/landing-page-v2/landing-page-v2-exchange-pattern.png" alt="Background Pattern Exchange" />
      </div>
      <div className="exchange__background--mobile exchange__background--pattern">
        <img className="exchange__pattern-bg" src="../assets/media/images/landing-page-v2/mobile-landing-page-v2-exchange-pattern.png" alt="Background Pattern Exchange" />
      </div>
      <div className="container">
        <div className="exchange__container">
          <div className="exchange__content">
            <h2 className="exchange__title" data-aos="fade-up">{t('exchange.title')}</h2>
            <p className="fb-regular exchange__subtitle" data-aos="fade-up" data-aos-delay="100">{t('exchange.subtitle')}</p>
            <div className="exchange__groups">
              <div className="exchange__items exchange__items--1" data-aos="fade-right" data-aos-delay="300">
                <div className="exchange-items__icons">
                  <img src="../assets/media/images/icons/add-user.svg" alt="Add User" />
                </div>
                <div className="exchange-items__detail">
                  <h5>{t('exchange.create_account')}</h5>
                </div>
              </div>
              <div className="exchange__items exchange__items--2" data-aos="fade-right" data-aos-delay="400">
                <div className="exchange-items__icons">
                  <img src="../assets/media/images/icons/bank.svg" alt="Bank" />
                </div>
                <div className="exchange-items__detail">
                  <h5>{t('exchange.link_bank_account')}</h5>
                </div>
              </div>
              <div className="exchange__items exchange__items--3" data-aos="fade-right" data-aos-delay="500">
                <div className="exchange-items__icons">
                  <img src="../assets/media/images/icons/wallet.svg" alt="Wallet" />
                </div>
                <div className="exchange-items__detail">
                  <h5>{t('exchange.start_trading')}</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="buy-sell-cards buy-sell-cards--v2" data-aos="zoom-in-up" data-aos-delay="600">
            <div className="buy-sell-cards__container">
              <div className="buy-sell-cards__header nav nav-tabs" id="buySellTab" role="tablist">
                <div className="nav-item" role="presentation">
                  <button className="nav-link fb-lg--bold buy-sell-cards__link active" id="buy-tab" data-bs-toggle="tab" data-bs-target="#buy-tab-pane" type="button" role="tab" aria-controls="buy-tab-pane" aria-selected="true">{t('exchange.buy')}</button>
                </div>
                <div className="separator"></div>
                <div className="nav-item" role="presentation">
                  <button className="nav-link fb-lg--bold buy-sell-cards__link" id="sell-tab" data-bs-toggle="tab" data-bs-target="#sell-tab-pane" type="button" role="tab" aria-controls="sell-tab-pane" aria-selected="false">{t('exchange.sell')}</button>
                </div>
              </div>
              <div className="tab-content" id="buySellTabContent">
                <div className="tab-pane fade show active" id="buy-tab-pane" role="tabpanel" aria-labelledby="buy-tab" tabindex="0">
                  <div className="buy-sell-cards__detail">
                    <div className="buy-sell-cards__detail--prices">
                      <p className="fb-regular notes">{t('exchange.price_note')}</p>
                      <p className="fd-md fd-md--bold value">53,260.20<span className="fb-lg currency">USD</span></p>
                    </div>
                    <div className="buy-sell-cards__detail--rewards">
                      <p className="fb-regular rewards">{t('exchange.claim_rewards')}</p>
                    </div>
                  </div>
                  <div className="buy-sell-cards__forms">
                    <div className="d-block forms-purchase__box">
                      <div className="forms-purchase">
                        <div className="forms-group forms-group--value">
                          <input type="number" min="0" value="4000" className="form-control forms-purchase__value" />
                        </div>
                        <div className="forms-purchase__line"></div>
                        <div className="forms-group forms-group--purchase js-forms-group--purchase" id="custom-select1">
                          <div className="forms-group__items selected" data-selected="usd">
                            <img className="fg-items__icon" src="../assets/media/images/icons/usd.svg" alt="" />
                            <p className="fg-items__value">USD</p>
                          </div>
                          <img className="forms-currency__icon-arrow-down" src="../assets/media/images/icons/arrow-down.svg" alt="" />
                          <div className="forms-group__dropdown js-forms-group__dropdown">
                            <div className="forms-group__items active" data-selected="usd">
                              <img className="fg-items__icon" src="../assets/media/images/icons/usd.svg" alt="" />
                              <p className="fg-items__value">USD</p>
                            </div>
                            <div className="forms-group__items" data-selected="btc">
                              <img className="fg-items__icon" src="../assets/media/images/icons/logo-btc.svg" alt="" />
                              <p className="fg-items__value">BTC</p>
                            </div>
                            <div className="forms-group__items" data-selected="eth">
                              <img className="fg-items__icon" src="../assets/media/images/icons/logo-ethereum.svg" alt="" />
                              <p className="fg-items__value">ETH</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-block forms-purchase__box">
                      <div className="forms-purchase">
                        <div className="forms-group forms-group--value">
                          <input type="number" min="0" value="4.75" className="form-control forms-purchase__value" />
                        </div>
                        <div className="forms-purchase__line"></div>
                        <div className="forms-group forms-group--purchase js-forms-group--purchase" id="custom-select2">
                          <div className="forms-group__items selected" data-selected="btc">
                            <img className="fg-items__icon" src="../assets/media/images/icons/logo-btc.svg" alt="" />
                            <p className="fg-items__value">BTC</p>
                          </div>
                          <img className="forms-currency__icon-arrow-down" src="../assets/media/images/icons/arrow-down.svg" alt="" />
                          <div className="forms-group__dropdown js-forms-group__dropdown">
                            <div className="forms-group__items" data-selected="usd">
                              <img className="fg-items__icon" src="../assets/media/images/icons/usd.svg" alt="" />
                              <p className="fg-items__value">USD</p>
                            </div>
                            <div className="forms-group__items active" data-selected="btc">
                              <img className="fg-items__icon" src="../assets/media/images/icons/logo-btc.svg" alt="" />
                              <p className="fg-items__value">BTC</p>
                            </div>
                            <div className="forms-group__items" data-selected="eth">
                              <img className="fg-items__icon" src="../assets/media/images/icons/logo-ethereum.svg" alt="" />
                              <p className="fg-items__value">ETH</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <a href="#" className="btn btn-primary buy-sell-cards__button">{t('exchange.buy_now')}</a>
                  </div>
                </div>
                <div className="tab-pane fade" id="sell-tab-pane" role="tabpanel" aria-labelledby="sell-tab" tabindex="0">
                  <div className="buy-sell-cards__detail">
                    <div className="buy-sell-cards__detail--prices">
                      <p className="fb-regular notes">{t('exchange.price_note')}</p>
                      <p className="fd-md fd-md--bold value">53,260.20<span className="fb-lg currency">USD</span></p>
                    </div>
                    <div className="buy-sell-cards__detail--rewards">
                      <p className="fb-regular rewards">{t('exchange.claim_rewards')}</p>
                    </div>
                  </div>
                  <div className="buy-sell-cards__forms">
                    <div className="d-block forms-purchase__box">
                      <div className="forms-purchase">
                        <div className="forms-group forms-group--value">
                          <input type="number" min="0" value="4000" className="form-control forms-purchase__value" />
                        </div>
                        <div className="forms-purchase__line"></div>
                        <div className="forms-group forms-group--purchase js-forms-group--purchase" id="custom-select3">
                          <div className="forms-group__items selected" data-selected="usd">
                            <img className="fg-items__icon" src="../assets/media/images/icons/usd.svg" alt="" />
                            <p className="fg-items__value">USD</p>
                          </div>
                          <img className="forms-currency__icon-arrow-down" src="../assets/media/images/icons/arrow-down.svg" alt="" />
                          <div className="forms-group__dropdown js-forms-group__dropdown">
                            <div className="forms-group__items active" data-selected="usd">
                              <img className="fg-items__icon" src="../assets/media/images/icons/usd.svg" alt="" />
                              <p className="fg-items__value">USD</p>
                            </div>
                            <div className="forms-group__items" data-selected="btc">
                              <img className="fg-items__icon" src="../assets/media/images/icons/logo-btc.svg" alt="" />
                              <p className="fg-items__value">BTC</p>
                            </div>
                            <div className="forms-group__items" data-selected="eth">
                              <img className="fg-items__icon" src="../assets/media/images/icons/logo-ethereum.svg" alt="" />
                              <p className="fg-items__value">ETH</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-block forms-purchase__box">
                      <div className="forms-purchase">
                        <div className="forms-group forms-group--value">
                          <input type="number" min="0" value="4.75" className="form-control forms-purchase__value" />
                        </div>
                        <div className="forms-purchase__line"></div>
                        <div className="forms-group forms-group--purchase js-forms-group--purchase" id="custom-select4">
                          <div className="forms-group__items selected" data-selected="btc">
                            <img className="fg-items__icon" src="../assets/media/images/icons/logo-btc.svg" alt="" />
                            <p className="fg-items__value">BTC</p>
                          </div>
                          <img className="forms-currency__icon-arrow-down" src="../assets/media/images/icons/arrow-down.svg" alt="" />
                          <div className="forms-group__dropdown js-forms-group__dropdown">
                            <div className="forms-group__items" data-selected="usd">
                              <img className="fg-items__icon" src="../assets/media/images/icons/usd.svg" alt="" />
                              <p className="fg-items__value">USD</p>
                            </div>
                            <div className="forms-group__items active" data-selected="btc">
                              <img className="fg-items__icon" src="../assets/media/images/icons/logo-btc.svg" alt="" />
                              <p className="fg-items__value">BTC</p>
                            </div>
                            <div className="forms-group__items" data-selected="eth">
                              <img className="fg-items__icon" src="../assets/media/images/icons/logo-ethereum.svg" alt="" />
                              <p className="fg-items__value">ETH</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <a href="#" className="btn btn-primary buy-sell-cards__button">{t('exchange.sell_now')}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ExchangeSection;
