import React from 'react';
import { useTranslation } from 'react-i18next';

function RoadMap() {
  const { t } = useTranslation();

  const sectionStyle = {
    backgroundImage: "url('../images/landing-page-pattern.svg')",
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  };

  return (
    <section className="timeline_area section_padding_130"  style={sectionStyle}>
      <div className="container">


        <div className="row justify-content-center">
          <div className="col-12 col-sm-8 col-lg-6">
          <h2 className="text-center feature__title ls-tight font-bolder display-3">
          {t('roadmap.text.roadMap')}
          </h2>
          <p className="text-center lead mb-5">
          {t('roadmap.text.ourCurrentAchievedGoalsAreTheVisionsWeGraduallyUnveil')}
          </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="apland-timeline-area">
              <div className="single-timeline-area">
                <div className="timeline-date wow fadeInLeft" data-wow-delay="0.1s">
                  <p>2023 / 8</p>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="single-timeline-content d-flex wow fadeInLeft" data-wow-delay="0.3s">
                      <div className="timeline-icon">
                        <i className="fa fa-address-card" aria-hidden="true"></i>
                      </div>
                      <div className="timeline-text">
                        <h6>{t('roadmap.text.firstStep')}</h6>
                        <p>{t('roadmap.text.theMarketIsBecomingMoreAndMoreCentralizedTheIdeaOfWalletPayInIsBorn')}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="single-timeline-content d-flex wow fadeInLeft" data-wow-delay="0.3s">
                      <div className="timeline-icon">
                        <i className="fa fa-address-card" aria-hidden="true"></i>
                      </div>
                      <div className="timeline-text">
                        <h6>{t('roadmap.text.development')}</h6>
                        <p>{t('roadmap.text.weTookOverTheEntireProjectAndStartedWorkingWeAlreadyHaveTheIOSApplicationAlmostFinished')}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="single-timeline-content d-flex wow fadeInLeft" data-wow-delay="0.3s">
                      <div className="timeline-icon">
                        <i className="fa fa-address-card" aria-hidden="true"></i>
                      </div>
                      <div className="timeline-text">
                        <h6>{t('roadmap.text.walletpayinApp')}</h6>
                        <p>{t('roadmap.text.theIOSApplicationIsAlmostReadyNextUpIsAndroidTheLaunchDateIsApproaching')}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="single-timeline-content d-flex wow fadeInLeft" data-wow-delay="0.3s">
                      <div className="timeline-icon">
                        <i className="fa fa-address-card" aria-hidden="true"></i>
                      </div>
                      <div className="timeline-text">
                        <h6>{t('roadmap.text.newToken')}</h6>
                        <p>{t('roadmap.text.weHaveDecidedThatAlongWithTheReleaseOfTheApplicationWeWillAlsoLaunchANewTokenCalledWPI')}</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="single-timeline-content d-flex wow fadeInLeft" data-wow-delay="0.3s">
                      <div className="timeline-icon">
                        <i className="fa fa-address-card" aria-hidden="true"></i>
                      </div>
                      <div className="timeline-text">
                        <h6>{t('roadmap.text.launchDate')}</h6>
                        <p>{t('roadmap.text.weHaveScheduledTheLaunchDateForBothTheApplicationAndTheTokenOnDecember12023')}</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="single-timeline-content d-flex wow fadeInLeft" data-wow-delay="0.3s">
                      <div className="timeline-icon">
                        <i className="fa fa-address-card" aria-hidden="true"></i>
                      </div>
                      <div className="timeline-text">
                        <h6>{t('roadmap.text.presaleToken')}</h6>
                        <p>{t('roadmap.text.weLaunchedAPriorityTokenPreSaleOf20OfTheTotalAmountAnyoneCanBeTheFirstToBuy')}</p>
                      </div>
                    </div>
                  </div>
                  
                  {/* ... (pokračujte s dalšími prvky) */}
                </div>
              </div>

              <div className="single-timeline-area">
  <div className="timeline-date wow fadeInLeft" data-wow-delay="0.1s">
    <p>2023 / 9</p>
  </div>
  <div className="row">
  <div className="col-12 col-md-6 col-lg-4">
      <div className="single-timeline-content d-flex wow fadeInLeft timeline-box" data-wow-delay="0.3s">
        <div className="timeline-icon">
          <i className="fa fa-address-card" aria-hidden="true"></i>
        </div>
        <div className="timeline-text">
          <h6>{t('roadmap.text.officialWeb')}</h6>
          <p>{t('roadmap.text.weDecidedThatItIsNecessaryToReworkTheEntireOfficialWebsiteBeforeLaunch')}</p>
        </div>
      </div>
    </div>
    <div className="col-12 col-md-6 col-lg-4">
      <div className="single-timeline-content d-flex wow fadeInLeft timeline-box" data-wow-delay="0.3s">
        <div className="timeline-icon">
          <i className="fa fa-address-card" aria-hidden="true"></i>
        </div>
        <div className="timeline-text">
          <h6>{t('roadmap.text.launchAppAndToken')}</h6>
          <p>{t('roadmap.text.onDecember12023TokenDistributionToInvestorsAndTheLaunchOfTheIOSAndroidAndNewTokenWillTakePlace')}</p>
        </div>
      </div>
    </div>
    <div className="col-12 col-md-6 col-lg-4">
      <div className="single-timeline-content d-flex wow fadeInLeft timeline-box" data-wow-delay="0.3s">
        <div className="timeline-icon">
          <i className="fa fa-address-card" aria-hidden="true"></i>
        </div>
        <div className="timeline-text">
          <h6>{t('roadmap.text.updates')}</h6>
          <p>{t('roadmap.text.theFirstUpdateOfErrorsInTheApplicationAndTheContinuationOfTheDevelopmentOfTheWebInterface')}</p>
        </div>
      </div>
    </div>
    
    {/* Přidejte další prvky fáze 2 podobně jako v předchozí fázi */}
  </div>
</div>

<div className="single-timeline-area">
  <div className="timeline-date wow fadeInLeft" data-wow-delay="0.1s">
    <p>2023 / 10</p>
  </div>
  <div className="row">
  <div className="col-12 col-md-6 col-lg-4">
      <div className="single-timeline-content d-flex wow fadeInLeft timeline-box" data-wow-delay="0.3s">
        <div className="timeline-icon">
          <i className="fa fa-address-card" aria-hidden="true"></i>
        </div>
        <div className="timeline-text">
          <h6>{t('roadmap.text.pinksale_presale')}</h6>
          <p>{t('roadmap.text.pinksle_descriptions')}</p>
        </div>
      </div>
    </div>
    <div className="col-12 col-md-6 col-lg-4">
      <div className="single-timeline-content d-flex wow fadeInLeft timeline-box" data-wow-delay="0.3s">
        <div className="timeline-icon">
          <i className="fa fa-address-card" aria-hidden="true"></i>
        </div>
        <div className="timeline-text">
          <h6>{t('roadmap.text.update_info')}</h6>
          <p>{t('roadmap.text.update_info_press')}</p>
        </div>
      </div>
    </div>
    <div className="col-12 col-md-6 col-lg-4">
      <div className="single-timeline-content d-flex wow fadeInLeft timeline-box" data-wow-delay="0.3s">
        <div className="timeline-icon">
          <i className="fa fa-address-card" aria-hidden="true"></i>
        </div>
        <div className="timeline-text">
          <h6>{t('roadmap.text.etherscan')}</h6>
          <p>{t('roadmap.text.etherscan_info')}</p>
        </div>
      </div>
    </div>
    

    
    
    {/* Přidejte další prvky fáze 2 podobně jako v předchozí fázi */}
  </div>
</div>

<div className="single-timeline-area">
  <div className="timeline-date wow fadeInLeft" data-wow-delay="0.1s">
    <p>2023 / 11</p>
  </div>
  <div className="row">
  <div className="col-12 col-md-6 col-lg-4">
      <div className="single-timeline-content d-flex wow fadeInLeft timeline-box" data-wow-delay="0.3s">
        <div className="timeline-icon">
          <i className="fa fa-address-card" aria-hidden="true"></i>
        </div>
        <div className="timeline-text">
          <h6>{t('roadmap.text.pinksale_launch')}</h6>
          <p>{t('roadmap.text.pinksle_launch_descriptions')}</p>
        </div>
      </div>
    </div>
    <div className="col-12 col-md-6 col-lg-4">
      <div className="single-timeline-content d-flex wow fadeInLeft timeline-box" data-wow-delay="0.3s">
        <div className="timeline-icon">
          <i className="fa fa-address-card" aria-hidden="true"></i>
        </div>
        <div className="timeline-text">
          <h6>{t('roadmap.text.start_launch')}</h6>
          <p>{t('roadmap.text.start_launch_desc')}</p>
        </div>
      </div>
    </div>


    

    
    
    {/* Přidejte další prvky fáze 2 podobně jako v předchozí fázi */}
  </div>
</div>

<div className="single-timeline-area">
  <div className="timeline-date wow fadeInLeft" data-wow-delay="0.1s">
    <p>2024 / 5</p>
  </div>
  <div className="row">
  <div className="col-12 col-md-6 col-lg-4">
      <div className="single-timeline-content d-flex wow fadeInLeft timeline-box" data-wow-delay="0.3s">
        <div className="timeline-icon">
          <i className="fa fa-address-card" aria-hidden="true"></i>
        </div>
        <div className="timeline-text">
          <h6>{t('roadmap.text.redesign')}</h6>
          <p>{t('roadmap.text.redesign_descript')}</p>
        </div>
      </div>
    </div>
    <div className="col-12 col-md-6 col-lg-4">
      <div className="single-timeline-content d-flex wow fadeInLeft timeline-box" data-wow-delay="0.3s">
        <div className="timeline-icon">
          <i className="fa fa-address-card" aria-hidden="true"></i>
        </div>
        <div className="timeline-text">
          <h6>{t('roadmap.text.dapp')}</h6>
          <p>{t('roadmap.text.dapp_comingsoon')}</p>
        </div>
      </div>
    </div>


    

    
    
    {/* Přidejte další prvky fáze 2 podobně jako v předchozí fázi */}
  </div>
</div>


  
              {/* ... (pokračujte s dalšími prvky) */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default RoadMap;
