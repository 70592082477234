import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

// Inicializace i18next
i18n
  .use(HttpApi) // Používání backendu pro načítání překladových souborů
  .use(LanguageDetector) // Detekce jazyka
  .use(initReactI18next) // Inicializace pro React
  .init({
    supportedLngs: ['en', 'cs', 'fr', 'de', 'ja', 'ru', 'zh'], // Podporované jazyky
    fallbackLng: 'en', // Výchozí jazyk, pokud není dostupný požadovaný
    detection: {
      order: ['path', 'cookie', 'htmlTag', 'localStorage', 'subdomain'],
      caches: ['cookie'],
    },
    backend: {
      loadPath: '/locales/{{lng}}.json', // Cesta k překladovým souborům
    },
    react: {
      useSuspense: false, // Možnost vypnutí suspense
    },
    interpolation: {
      escapeValue: false, // React již bezpečně escapuje hodnoty
    },
  });

export default i18n;
