import React from 'react';

function Terms() {
    const lastUpdated = "September 9, 2023";
  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">Terms Of Service</h2>

      <div className="term">
      <h4 className="mb-3 mt-3">Agreement to Terms</h4>

        <p>
          By accessing or using any or all of the Services provided by Walletpayin, you expressly acknowledge that (i) you have read and understood these Terms; (ii) you agree to be bound by these Terms; and (iii) you are legally competent to enter into these Terms. If you do not agree to be bound by these Terms or any updates or modifications to these Terms, you may not access or use our Services.
        </p>
      </div>

      <div className="term">
      <h4 className="mb-3 mt-3">Privacy Policy</h4>

        <p>
          For an explanation of how we collect, use and disclose information from our users, please see our Privacy Policy at [insert link to your Privacy Policy]. You acknowledge and agree that your use of the Services is subject to, and that we can collect, use and/or disclose your information (including any personal data you provide to us) in accordance with our Privacy Policy.
        </p>
      </div>

      <div className="term">
      <h4 className="mb-3 mt-3">Updates to Terms or Services</h4>

        <p>
          We reserve the right to update or modify these Terms at any time at our sole discretion. If we do so, we’ll let you know by either posting the revised Terms on our website, on our mobile application or through other methods of communication which we deem reasonable. Such revised Terms as posted will take effect immediately, unless otherwise indicated. You should regularly check our website to inform yourself of any such changes and decide whether or not to accept the revised version of these Terms. If you continue to use Walletpayin following any update or modification of the Terms you shall be deemed to have accepted the revised Terms. If you do not agree to the Terms or any update or modification to the Terms, you must cease to access or use our Services.
        </p>
      </div>

      <div className="term">
      <h4 className="mb-3 mt-3">Eligibility</h4>

        <p>
          To be eligible to use Walletpayin (i) you must be at least eighteen (18) years old and legally competent to enter into these Terms; (ii) you must not be a resident of sanctioned jurisdictions according to any trade embargoes, UN Security Council Resolutions (“UNSCR”) or HM Treasury's financial sanctions regime; and (iii) you must not be currently the subject of or subject to economic sanctions such as the United Nations Security Council Sanctions List, the list of specially designated nationals maintained by OFAC, the denied persons or entity list of the U.S. Department of Commerce or any similar list maintained by any other relevant sanctions authority. If you are using our Services on behalf of a legal entity, you further represent and warrant that (iv) the legal entity is duly organized and validly existing under the applicable laws of the jurisdiction of its organization; and (v) you are duly authorized by such legal entity to act on its behalf. You can only use our Services if permitted under the laws of your jurisdiction. For the avoidance of doubt, you may not use our Services if you are located in, or a citizen or resident of any state, country, territory or other jurisdiction where your use of our Services would be illegal or otherwise violate any applicable laws. Please make sure that these Terms are in compliance with all laws, rules, and regulations that apply to you. You agree that you are only using our Services with legally-obtained funds that rightfully belong to you. By using Walletpayin, you represent and warrant that you meet all eligibility requirements that we outline in these Terms. We may still refuse to let certain people access or use Walletpayin, however, and we reserve the right to change our eligibility criteria at any time.
        </p>
      </div>
      <p><br></br></p>
      <div className="term">
  <h4 className="mb-3 mt-3">Our Services</h4>

  <p>
    Walletpayin is a cutting-edge non-custodial wallet software designed for managing a range of digital assets including cryptocurrencies, virtual commodities, and NFTs (“Digital Assets”). This empowers you with complete control and responsibility over your Digital Assets and private keys, enabling you to authorize transactions directly from your wallet address. Please acknowledge and understand that as Walletpayin operates as a non-custodial wallet software, you bear sole responsibility for your activity and any associated risks of loss at all times.
  </p>

  <p>
    With Walletpayin, you can
  </p>

  <ul>
    <li>create wallet addresses and their corresponding private keys for sending and receiving digital assets;</li>
    <li>explore and access third-party decentralized applications (“DexWallet”) and decentralized exchanges (“DEX”) seamlessly through the mobile application’s integrated web browser;</li>
    <li>exchange/trade digital assets using WalletApp functionality facilitated by third-party service provider(s);</li>
    <li>participate in staking certain digital assets within a third-party ‘proof of stake’ network through our Staking Service;</li>
    <li>view up-to-date digital asset price information made available by third-party service provider(s); and</li>
    <li>efficiently broadcast Digital Asset Transaction data to various supported blockchains without requiring you to download or install any associated blockchain-based software on your local device.</li>
  </ul>
</div>

<div className="term">
  <h4 className="mb-3 mt-3">Wallet Address, Private Key, and Backup Capabilities</h4>

  <p>
    Walletpayin offers an encrypted backup option for specific information linked to your wallet, which can be securely stored on compatible devices. The private key, in conjunction with the wallet address, empowers you to authorize the transfer of Digital Assets to and from that specific wallet address. Safeguarding your private key and any mnemonic phrase (“Secret Phrase”) associated with your wallet is your exclusive responsibility. Ensure the security of your wallet address, Secret Phrase, and private key access details. It is imperative that you create backups of your private keys, backup phrases, or passwords. Failure to do so may lead to the loss of control over Digital Assets connected to your wallet. Please be aware that we do not receive or store your wallet password, encrypted private key, unencrypted private key, or Secret Phrase associated with your wallet. If you happen to forget your original password, we are unable to generate a new one for you. If you have not securely stored a backup of any wallet address and private key pairs maintained in your wallet, please acknowledge and accept that any Digital Assets associated with such wallet address will become inaccessible. Consequently, we shall bear no responsibility or liability in the event you encounter difficulties accessing your wallet, including, but not limited to, your failure to maintain the security of your wallet address, Secret Phrase, and private key information.
  </p>
</div>

<div className="term">
  <h4 className="mb-3 mt-3">Utilizing DexWallet and DEX</h4>

  <p>
    Should you choose to access or use DexWallet or DEX, including, but not limited to, WalletApp functionality integrated within our Services such as the Trade/Swap Digital Assets feature, kindly acknowledge and understand the following (i) Walletpayin is not liable for your access or use of DexWallet or DEX and assumes no responsibility whatsoever in connection with your utilization of DexWallet or DEX, including, but not limited to, any transactions you may dispute; (ii) the limits on the amounts you may exchange via DEX per day are subject to the requirements of third-party developed Smart Contracts; (iii) blockchain operations are irreversible, meaning you are solely accountable for the consequences of any issues associated with such transactions conducted via DexWallet or DEX, including, but not limited to, inadvertently transferring to an incorrect address or encountering problems related to the node servers you have selected; (iv) when utilizing DexWallet or DEX, third-party developed Smart Contracts may impose handling fees and/or service fees, and any information displayed on Walletpayin pertaining to such fees is provided for your reference only. Walletpayin cannot and does not guarantee its accuracy, applicability, reliability, integrity, or appropriateness. Furthermore, Walletpayin shall not be held liable for any loss or damage that may be directly or indirectly caused by your use of this content; and (v) Walletpayin currently does not impose any service fees or handling fees for the use of DexWallet or DEX. However, please be aware that Walletpayin may, at its discretion, choose to implement such fees at any point in the future. Any updated fees will be applicable to transactions occurring after the effective date of the fee revision.
  </p>
</div>
<div className="term">
  <h4 className="mb-3 mt-3">Utilizing Staking Services</h4>

  <p>
    Should you choose to engage with the Staking Service, a third party may handle the staking of specific Digital Assets on your behalf, acting as a transaction validator within the applicable blockchain network. If a block of transactions is successfully validated using these staked Digital Assets, you may receive a reward from the network (referred to as a "Staking Reward"). Please understand and acknowledge that Walletpayin (i) assumes no responsibility for your use of the Staking Service and bears no liability in connection with it; (ii) cannot guarantee uninterrupted staking services; and (iii) cannot guarantee that any particular Digital Asset will be staked or continue to be staked.
  </p>
</div>

<div className="term">
  <h4 className="mb-3 mt-3">Acquiring Digital Assets via Supported Credit or Debit Cards</h4>

  <p>
    If you opt to procure Digital Assets using your credit or debit card through Walletpayin, a third party will facilitate the conversion of your selected amount into the relevant Digital Asset, which will then be credited to your wallet. Please acknowledge and agree that (i) Walletpayin assumes no liability for your use of such third-party service; (ii) you will be subject to any terms and conditions set forth by the relevant third-party service provider; (iii) Walletpayin lacks the ability to modify, withdraw, or cancel any conversion requests initiated by you; (iv) Walletpayin is not responsible for any exchange rates provided by the relevant third-party service provider(s); and (v) Walletpayin assumes no responsibility for any fees charged by the relevant third-party service provider(s), your bank, or your credit card issuer(s).
  </p>
</div>

<div className="term">
  <h4 className="mb-3 mt-3">Digital Asset Transactions</h4>

  <p>
    In order for any proposed Digital Asset transactions to be finalized, they must be confirmed and recorded within the associated public blockchain of the respective Digital Asset. These networks are decentralized, peer-to-peer networks supported by independent third parties, over which we have no ownership, control, or operation. We exert no authority over these blockchain networks and, consequently, cannot ensure the confirmation and processing of any transaction details you submit through our Services. By utilizing Walletpayin, you acknowledge and agree that (i) we lack the capability to cancel or otherwise alter your transaction; (ii) the transaction details you submit may not be completed, or may be subject to significant delays, by the relevant blockchain networks; (iii) we do not store, transmit, or receive Digital Assets; and (iv) any transfer pertaining to any Digital Asset occurs on the relevant blockchain network, not on a network owned or operated by us. Consequently, we do not guarantee the transfer of title or rights in any Digital Asset.
  </p>
</div>

<div className="term">
  <h4 className="mb-3 mt-3">Accuracy of User-Provided Information</h4>

  <p>
    You declare and guarantee that any information you furnish through our Services is both accurate and comprehensive. Please be aware that we bear no responsibility for any errors or omissions that may occur in connection with any Digital Asset transaction initiated through our Services. We strongly encourage you to thoroughly review your transaction details before attempting to execute a Digital Asset transfer.
  </p>
</div>
<div className="term">
  <h4 className="mb-3 mt-3">Wallet Registration and Security</h4>

  <p>
    To utilize Walletpayin, you must either import an existing wallet or create a new one. Upon wallet creation, a private key will be assigned to you. It is imperative that you promptly inform us of any unauthorized use of your private key or any security breaches related to your wallet. However, please acknowledge that you bear all risks associated with utilizing the Services and are solely responsible for safeguarding the confidentiality and security of your private key. When creating a wallet, it is highly recommended to take precautionary measures to prevent potential loss of access or control over your wallet. These measures include, but are not limited to (a) creating a robust and unique password distinct from any others you use (e.g., different from your phone password or any other online passwords); (b) refraining from storing the private key and Secret Phrase in plain text online or in an insecure physical location; (c) restricting access to your devices and wallet; (d) implementing necessary safeguards against malware on your devices and networks; and (e) promptly notifying us of any suspected or discovered security breaches associated with your wallet. Despite anything to the contrary herein, we shall bear no liability in connection with activities on your wallet, whether with or without your authorization.
  </p>
</div>

<div className="term">
  <h4 className="mb-3 mt-3">Push Notifications</h4>

  <p>
    You may opt to receive push notifications from Walletpayin, which will provide alerts regarding congested blockchain networks supported by Walletpayin and completed transactions involving your wallet. To receive push notifications, you must opt in via the "Settings" and enable "Push Notifications."
  </p>
</div>

<div className="term">
  <h4 className="mb-3 mt-3">Fees</h4>

  <p>
    Any applicable fees for the Services or their components, if applicable, will be detailed on walletpayin.com and/or the Walletpayin mobile application/extension.
  </p>
</div>

<div className="term">
  <h4 className="mb-3 mt-3">Transaction Fees</h4>

  <p>
    Certain virtual currency transactions may incur transaction fees (e.g., mining fees) mandated by the virtual currency system or blockchain network you are engaging with. Prior to initiating a transaction, you must ensure that your wallet contains a sufficient balance and/or "gas" to complete the transaction. Please recognize and agree that we are not liable for any failed transactions or losses you may incur due to incorrectly set transaction fees (i.e., too low or too high) or due to inadequate funds or gas associated with your wallet address. Additionally, we do not have access to your transactions or those of any other party.
  </p>
</div>

<div className="term">
  <h4 className="mb-3 mt-3">Discounts, Referral Bonuses, and Other Payments</h4>

  <p>
    Users may enjoy specific discounts for applicable transactions via our mobile application from time to time. Furthermore, users may benefit from discounts through our affiliate partnerships with third-party services. We may receive referral bonuses or commissions for qualifying user referrals to select third-party service providers with whom we have established partnerships. The amount and terms of such bonuses are determined in accordance with the provisions of the respective agreements. By using our mobile application, you agree to adhere to and be bound by all terms and conditions, including those outlined in this section regarding "Discounts, Referral Bonuses, and Other Payments," as well as any forthcoming changes or updates communicated through our website, mobile application, or other reasonable means of communication (which changes or updates will become effective on the indicated dates). If you do not agree to the Terms or any updates or modifications to the Terms, you must discontinue accessing or using our Services.
  </p>
</div>

<div className="term">
  <h4 className="mb-3 mt-3">Taxes</h4>

  <p>
    Determining the applicable taxes for transactions you submit via our Services is your responsibility. It is also your responsibility to accurately report and remit the appropriate taxes to the relevant tax authority. You acknowledge that we are not responsible for determining the tax implications of your transactions or for handling, reporting, withholding, or remitting any taxes arising from Digital Asset-related transactions.
  </p>
</div>
<div className="term">
<h4 className="mb-3 mt-3">Third Party Services and Content</h4>

  <p>
    In no event shall a description or reference to a third party’s product or service (including, but not limited to, providing a description or reference via hyperlink) be construed as an endorsement or promotion of such third party products or services by us. We retain the exclusive right to add to, modify, or cancel the availability of any Third Party Service.
  </p>

  <p>
    You may agree to receive push notifications from Third Party Content providers. In order to receive push notifications, you must opt in to the service. Push notifications will not be automatically enabled on your device for Third Party Content.
  </p>

  <p>
    We do not control, endorse, or adopt any Third Party Content shared through push notifications, and will have no responsibility for Third Party Content including, but not limited to, token availability and/or sales.
  </p>

  <p>
    If, to the extent permitted by Walletpayin, you grant express permission to a third party to access or connect to your Walletpayin account, either through the third party’s product or service or through Walletpayin, you acknowledge that granting permission to a third party to take specific actions on your behalf does not relieve you of any of your responsibilities under these Terms. You are fully responsible for all acts or omissions of any third party with access to your wallet.
  </p>
</div>

<div className="term">
<h4 className="mb-3 mt-3">Intellectual Property</h4>

  <p>
    <strong>License</strong> As long as you agree to and comply with the present Terms, we grant you a non-exclusive, non-sublicensable and non-transferable license to use Walletpayin for your personal use or internal business use only.
  </p>

  <p>
    Except as otherwise expressly permitted in these Terms, you will not (a) reproduce, modify, adapt or create derivative works of any part of Walletpayin; (b) rent, lease, distribute, sell, sublicense, transfer, or provide access to Walletpayin; (c) use Walletpayin for the benefit of any third party.
  </p>

  <p>
    <strong>Feedback</strong> From time to time, you may choose to submit feedback to us. We may, in connection with Walletpayin, freely use, copy, disclose, license, distribute and exploit any feedback in any manner without any obligation, royalty or restriction based on intellectual property rights or otherwise.
  </p>

  <p>
    No feedback will be considered your Confidential Information, and nothing in this Agreement limits our right to independently use, develop, evaluate, or market products or services, whether incorporating feedback or otherwise.
  </p>

  <p>
    <strong>Open Source</strong> Walletpayin was developed under a GPL open source license. Information regarding Walletpayin’s current software can be found at [insert link to your open-source repository].
  </p>

  <p>
    Since software development is community-based, it can be accessed, used, and shared, in modified or unmodified form, by anyone. Please be aware that GPL is a copyleft license, which means that any derivative works can only be distributed under the same license terms as the original software. If you have any questions, you should review GNU’s terms and conditions at [insert link to GNU's terms and conditions].
  </p>

  <p>
    <strong>Content</strong> Walletpayin may contain copyrighted material and trademarks including, but not limited to, text and graphics (the “Content”), which is protected by copyright law, registered and unregistered trademarks, and other intellectual property rights.
  </p>
</div>
<div className="terms">
  <h4 className="mb-3 mt-3">Your Use of Walletpayin</h4>

  <p>
    By using Walletpayin, you agree to abide by all applicable laws, whether they are local, provincial, state, federal, national, or international in scope. These laws govern your use of our Services, and you are prohibited from using Walletpayin to facilitate, support, or engage in any illegal activities. This includes, but is not limited to, activities such as illegal trade, unlawful gambling, fraud, money laundering, or any form of terrorist activity.
  </p>

  <p>
    It's important to note that if we discover that you have violated these Terms or any applicable laws, including regulatory requirements like the Bank Secrecy Act, we reserve the right to take appropriate actions against you under the law.
  </p>

  <p>
    You also agree that you will not
  </p>

  <ul>
    <li>Encourage or induce any third party to engage in prohibited activities as outlined in this section.</li>
    <li>Impersonate another individual or attempt to use someone else's wallet without proper authorization.</li>
    <li>Disrupt or interfere with other users' enjoyment of Walletpayin.</li>
    <li>Distribute viruses or malicious computer code via Walletpayin.</li>
    <li>Engage in actions that could overload or harm our infrastructure or that of our third-party providers.</li>
    <li>Attempt to reverse-engineer, bypass, or circumvent any security measures implemented by Walletpayin.</li>
    <li>Violate the rights of Walletpayin, our users, or others, including privacy, publicity, intellectual property, or proprietary rights.</li>
  </ul>

  <p>
    While we are not obligated to monitor user content, we have the discretion to take action without notice if you violate these Terms. Any unauthorized use of Walletpayin is strictly prohibited and may result in the immediate termination of your license to use our Services.
  </p>

  <p>
    You acknowledge that by using Walletpayin, you may encounter content that is offensive, indecent, or objectionable. We are not responsible for such content and assume no liability for any loss or damage related to user-generated content.
  </p>
</div>

<div className="terms">
  <h4 className="mb-3 mt-3">Limitation of Liability & Disclaimer of Warranties</h4>

  <p>
    You recognize and agree that we have no control over and are not responsible for
  </p>

  <ul>
    <li>Failures, disruptions, errors, or delays in processing Digital Assets while using the Services.</li>
    <li>Risks associated with hardware, software, and internet connectivity failures.</li>
    <li>Risks related to malicious software within Walletpayin's software.</li>
    <li>Risks of unauthorized access to information stored within your wallet, including your wallet address, private key, and Secret Phrase.</li>
    <li>Risks of unknown vulnerabilities or unexpected changes in blockchain networks.</li>
  </ul>

  <p>
    You release us from all liability for losses, damages, or claims arising from various scenarios, including user errors, server failures, unauthorized access, software bugs, and unauthorized third-party activities.
  </p>

  <p>
    We make no representations about Third Party Content accessed through our Services. Any additional terms, conditions, warranties, or representations regarding such content are solely between you and the respective parties.
  </p>

  <h4 className="mb-3 mt-3">Limitation of Liability</h4>

  <p>
    To the maximum extent permitted by applicable law, we, our affiliates, shareholders, directors, officers, employees, attorneys, agents, representatives, suppliers, and contractors shall not be liable for any direct or indirect damages or liabilities of any kind, including, but not limited to, loss of data, revenue, profits, or other financial benefits, arising from your use of the Services. This includes scenarios such as unauthorized use of your wallet address and private key due to a breach of confidentiality, interruptions in service, software bugs, or third-party attacks.
  </p>

  <p>
    Some jurisdictions do not allow the exclusion or limitation of liability for consequential or incidental damages, so the above limitation may not apply to you. In no event shall our total liability to you exceed one hundred U.S. dollars ($USD100.00) or its equivalent in the local currency of your jurisdiction, except as may be required by applicable law in cases involving personal injury.
  </p>
</div>
<div className="terms">
  <h4 className="mb-3 mt-3">Warranty Disclaimer</h4>
  <p>
    Walletpayin is provided "as is" and "as available", and without warranty of any kind. To the maximum extent permitted by law,
    we disclaim all representations and warranties, express or implied, relating to the services and underlying software or any aspect
    of the information, content, or the services, whether provided or owned by us, or by any third party or contained in any third party
    materials or on any third party websites accessible or linked to our website or the services, including without limitation, warranties
    of merchantability, fitness for a particular purpose, title, non-infringement, freedom from computer virus, and any implied warranties
    arising from course of dealing, course of performance, or usage in trade, all of which are expressly disclaimed. In addition, we do not
    represent or warrant that the content accessible via the services is accurate, complete, available, current, free of viruses or other
    harmful components, or that the results of using the services will meet your requirements. We do not make any representations or give
    warranties that the access to Walletpayin or use of the services and the functionality thereof will be continuous, uninterrupted, timely
    or error-free. Some states do not allow the disclaimer of implied warranties, so the foregoing disclaimers may not apply to you.
  </p>

  <h4 className="mb-3 mt-3">Indemnity</h4>
  <p>
    To the extent permitted by applicable law, you agree to defend, indemnify, and hold harmless us, our affiliates, and our respective shareholders,
    members, directors, officers, employees, attorneys, agents, representatives, suppliers and contractors, from and against any and all claims,
    damages, obligations, losses, liabilities, tort, costs or debt, and expenses (including, but not limited to, attorney’s fees) arising from (a)
    your use of and access to the Services; (b) any feedback or submissions you provide to us concerning Walletpayin; (c) violation of any Third
    Party Content (d) your violation of the Terms; or (e) your violation of any law, rule, or regulation, or the rights of any third party.
  </p>

  <h4 className="mb-3 mt-3">Time Limitation on Claims</h4>
  <p>
    You agree that any claim you may have arising out of or related to your relationship with us must be filed within one year after such claim arises,
    otherwise, your claim is permanently barred.
  </p>

  <h4 className="mb-3 mt-3">Governing Law</h4>
  <p>
    No matter where you’re located, the laws of the State of California will govern these Terms and the parties’ relationship as if you signed these Terms
    in California, without regard to California’s conflicts of laws rules. If any provisions of these Terms are inconsistent with any applicable law,
    those provisions will be superseded or modified only to the extent such provisions are inconsistent. The parties agree to submit to the federal or
    state courts in California for exclusive jurisdiction of any dispute arising out of or related to your use of the Services or your breach of these Terms.
    You waive any objection based on lack of personal jurisdiction, place of residence, improper venue, or forum non conveniens in any such action.
  </p>

  <h4 className="mb-3 mt-3">Note to International Users</h4>
  <p>
    If you are a user accessing or using our Services from a region with laws or regulations governing personal data collection, use, and disclosure that differ
    from United States laws, please be advised that we do not collect or process your personal data, except as provided for in our Privacy Policy.
  </p>

  <h4 className="mb-3 mt-3">Termination</h4>
  <p>
    In the event of termination concerning your license to use Walletpayin, your obligations under this Agreement will still continue. Your access to the funds
    in your Wallet after termination will depend on your access to your backup of your Wallet address and private key.
  </p>

  <h4 className="mb-3 mt-3">Discontinuance of Services</h4>
  <p>
    We may, in our sole discretion and without cost to you, with or without prior notice, and at any time, modify or discontinue, temporarily or permanently,
    any portion of our Services. You are solely responsible for storing outside of the Services a backup of any wallet address and private key pair that you
    maintain in your wallet. Maintaining an external backup of any wallet address and private key pairs associated with your wallet will allow you to access
    the blockchain network upon which your wallet is secured. Such a backup will allow you to fully restore your wallet at any time without cost or loss of
    your Digital Assets. If you do not maintain a backup of your wallet data outside of the Services, you will not be able to access the Digital Assets associated
    with your wallet. Walletpayin shall not be held responsible or liable for any loss of Digital Assets in the event that we discontinue all or any part of
    the Services.
  </p>
</div>
<div className="terms">
  <h4 className="mb-3 mt-3">No Waiver</h4>
  <p>
    In connection with this Agreement, any failure on our part to exercise, or any delay in exercising, any right, power, or privilege
    under this Agreement shall not operate as a waiver. Nor shall any single or partial exercise of any right, power, or privilege
    preclude any other or further exercise thereof. The waiver of any such right or provision will be effective only if in writing and
    signed by a duly authorized representative of us. Except as expressly set forth in this Agreement, the exercise by either party of
    any of its remedies under this Agreement will be without prejudice to its other remedies under this Agreement or otherwise.
  </p>

  <h4 className="mb-3 mt-3">Severability</h4>
  <p>
    If it is determined that any part of this Agreement is invalid, void, or unenforceable for any reason, that term will be deemed
    severable and limited or eliminated to the minimum extent necessary. The limitation or elimination of the term shall not affect or
    impair the validity or enforceability of any remaining part of that term, clause, or provision, or any other terms, clauses, or provisions
    of these Terms.
  </p>

  <h4 className="mb-3 mt-3">Arbitration & Waiver of Class Action</h4>
  <p>
    The parties involved agree to settle through arbitration any dispute arising from this Agreement or your use of the Services on an individual
    basis. Arbitration prevents you from filing a lawsuit in court or having a jury trial. The parties expressly waive trial by jury. The parties
    agree that (a) any arbitration will occur in San Francisco, California; and (b) the arbitration will be conducted confidentially by a single
    arbitrator following the rules of the American Arbitration Association for arbitration of consumer-related disputes, in the English language,
    and with limited discovery. Upon your request, hearings may be conducted in person or by telephone, and the arbitrator may decide on motions based
    on briefs, without oral hearings. Apart from class procedures and remedies discussed below, the arbitrator has the authority to grant any remedy
    that would otherwise be available to a court or other tribunal. The prevailing party in any action or proceeding to enforce these Terms shall be
    entitled to costs and attorneys' fees. The arbitral decision may be enforced in any court. Regardless of whether the dispute is heard in arbitration
    or in court, you and Walletpayin will not initiate against the other a class action, class arbitration, or representative action or proceeding.
  </p>

  <h4 className="mb-3 mt-3">Force Majeure</h4>
  <p>
    We shall not be held responsible for any delays, failure in performance, or interruptions of service resulting directly or indirectly from any cause
    or condition beyond our reasonable control. This includes, but is not limited to any delay or failure due to acts of God, acts of civil or military
    authorities, acts of terrorism, civil or industrial disturbances, blockages, embargoes, war, strikes or other labor disputes, fire, earthquakes, storms
    or other nature-related events, interruption in electrical telecommunications or Internet services or network provider services, failure of hardware equipment
    and/or software, or other utility failures, smart contract bugs or weaknesses, technological changes, changes in interest rates or other monetary conditions,
    and, for the avoidance of doubt, changes to any blockchain-related protocol, other catastrophe, or any other occurrences beyond our reasonable control.
    This shall not affect the validity and enforceability of any remaining provisions. If we are unable to perform our Services outlined in the Terms due to
    factors beyond our control, including, but not limited to, the aforementioned force majeure events or changes in applicable laws and/or sanctions policies,
    we shall not be liable for the Services provided under these Terms during the time period coincident with the event in question.
  </p>
</div>
<div className="terms">
  <h4 className="mb-3 mt-3">Assignment</h4>
  <p>
    You acknowledge and agree that we have the right to assign any of our rights and/or transfer, sub-contract, or delegate any of our obligations
    under these Terms without any notice or consent from you. These Terms will bind and inure to the benefit of the parties, their successors, and
    permitted assigns. Your agreement to these Terms is personal to you, and you may not transfer or assign it to any third party.
  </p>

  <h4 className="mb-3 mt-3">No Third Party Beneficiaries</h4>
  <p>
    You acknowledge and agree that, except as otherwise expressly provided in these Terms, there shall be no third party beneficiaries to the Terms.
  </p>

  <h4 className="mb-3 mt-3">Entire Agreement</h4>
  <p>
    This Agreement constitutes the entire understanding and agreement as to the subject matter hereof. It supersedes any and all prior discussions,
    agreements, and understandings of any kind (including, without limitation, any prior versions of the Terms) and of every nature between us. Except
    as provided above, any modification to these Terms must be in writing and signed by both parties.
  </p>

  <h4 className="mb-3 mt-3">Notices</h4>
  <p>
    Any notices or other communications provided by us under these Terms, including those regarding modifications to it, will be given by posting to
    the Services and/or through other electronic communication. You agree and consent to receive electronically all communications, agreements,
    documents, notices, and disclosures that we provide in connection with your account and your use of the Services.
  </p>

  <h4 className="mb-3 mt-3">Questions or Comments</h4>
  <p>
    If you have any questions regarding these Terms, your rights and obligations arising from these Terms, your use of Walletpayin and our Services,
    or any other matter, please send us a message on our contact page at <a href="mailtosupport@walletpayin.com">support@walletpayin.com</a>.
  </p>
</div>
<p className="text-center mt-4">Last Updated: {lastUpdated}</p>


    </div>
  );
}

export default Terms;
