import React from 'react';

function Token() {
  return (
    <section className="exchange">
      <div className="exchange__background exchange__background--pattern">
        <img className="exchange__pattern-bg" src="../assets/media/images/landing-page-v2/landing-page-v2-exchange-pattern.png" alt="Background Pattern Exchange" />
      </div>
      <div className="exchange__background--mobile exchange__background--pattern">
        <img className="exchange__pattern-bg" src="../assets/media/images/landing-page-v2/mobile-landing-page-v2-exchange-pattern.png" alt="Background Pattern Exchange" />
      </div>
      <div className="container">
        <div className="exchange__container">
          <div className="exchange__content">
            <h2 className="exchange__title" data-aos="fade-up">Token Information</h2>
            <p className="fb-regular exchange__subtitle" data-aos="fade-up" data-aos-delay="100">
              Detailed information about the token, its utility, distribution, and other relevant details.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Token;
