import React from 'react';

function Privacy() {
    const lastUpdated = "September 9, 2023";
    return (
        <div className="container mt-5">
            <h2 className="text-center mb-4">Privacy Policy</h2>

            <div className="term">
                <p>
                    Thank you for visiting the website for Walletpayin app.
                </p>
                <p>
                    The Walletpayin app doesn't collect any data or use analytics tools that may expose any data about its users. The wallet is designed to ensure a high level of privacy for its users.
                </p>
                <ul>
                    <li>
                        user data always remains on the user's device.
                    </li>
                    <li>
                        the wallet doesn't collect any data about users.
                    </li>
                    <li>
                        the wallet doesn't share any data about users.
                    </li>
                    <li>
                        there are no user accounts or databases keeping user data elsewhere.
                    </li>
                </ul>
                <p>
                    This website neither collects any data about its users nor employs any third-party analytics or data collecting services.
                </p>
            </div>

            <div className="term">
                <p className="text-center mt-4">Last Updated: {lastUpdated}</p>
            </div>
        </div>
    );
}

export default Privacy;
