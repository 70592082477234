import React from 'react';

const HeroSection = () => {
  return (
    <section className="hero hero-v2">
      <div className="hero__background hero__background--grid">
        <img className="hero__grid-bg" src="/assets/media/images/landing-page-v2/landing-page-v2-hero-grid-bg.png" alt="Background Grid Hero" />
      </div>
      <div className="hero__background hero__background--pattern">
        <img className="hero__pattern-bg" src="/assets/media/images/landing-page-v2/landing-page-v2-hero-pattern.png" alt="Background Pattern Hero" />
      </div>
      <div className="hero__background--mobile hero__background--grid">
        <img className="hero__grid-bg" src="/assets/media/images/landing-page-v2/mobile-landing-page-v2-hero-grid.png" alt="Background Grid Hero" />
      </div>
      <div className="hero__background--mobile hero__background--pattern">
        <img className="hero__pattern-bg" src="/assets/media/images/landing-page-v2/mobile-landing-page-v2-hero-pattern.png" alt="Background Pattern Hero" />
      </div>
      <div className="container">
        <div className="hero__container">
          <div className="hero__content" data-aos="fade-right">
            <h1 className="hero__title">A trusted and secure cryptocurrency exchange.</h1>
            <p className="fb-lg hero__subtitle">Your guide to the world of an open financial system. Get started with the easiest and most secure platform to buy and trade cryptocurrency.</p>
            <a href="#" className="btn btn-primary btn-lg btn-pill hero__link">Get Started Now</a>
          </div>
          <div className="hero__illustration" data-aos="zoom-in-up" data-aos-delay="100">
            <img src="/assets/media/images/landing-page-v2/landing-page-v2-hero-illustration.png" alt="Hero Illustration" />
          </div>
        </div>
      </div>
    ś
    </section>
  );
};

export default HeroSection;
