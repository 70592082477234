import React from 'react';
import { useTranslation } from 'react-i18next';

const FeatureSection = () => {
  const { t } = useTranslation();

  return (
    <section className="feature">
      <div className="feature__background feature__background--pattern">
        <img className="feature__pattern-bg" src="../assets/media/images/landing-page-v2/landing-page-v2-feature-pattern.png" alt="Background Pattern Feature" />
      </div>
      <div className="feature__background--mobile feature__background--pattern">
        <img className="feature__pattern-bg" src="../assets/media/images/landing-page-v2/mobile-landing-page-v2-feature-pattern.png" alt="Background Pattern Feature" />
      </div>
      <div className="container">
        <div className="feature__container">
          <div className="feature__group">
            <div className="feature__items feature__items--1" data-aos="zoom-in-up" data-aos-delay="100">
              <div className="feature-items__img">
                <img src="../assets/media/images/icons/icon-portofolio-manager.svg" alt="Portofolio Manager" />
              </div>
              <h5 className="feature-items__title">{t('feature.portfolio_manager_title')}</h5>
              <p className="fb-regular feature-items__desc">{t('feature.portfolio_manager_desc')}</p>
            </div>
            <div className="feature__items feature__items--2" data-aos="zoom-in-up" data-aos-delay="200">
              <div className="feature-items__img">
                <img src="../assets/media/images/icons/phone.svg" alt="Mobile Apps" />
              </div>
              <h5 className="feature-items__title">{t('feature.mobile_apps_title')}</h5>
              <p className="fb-regular feature-items__desc">{t('feature.mobile_apps_desc')}</p>
            </div>
            <div className="feature__items feature__items--3" data-aos="zoom-in-up" data-aos-delay="300">
              <div className="feature-items__img">
                <img src="../assets/media/images/icons/lock.svg" alt="Vault protection" />
              </div>
              <h5 className="feature-items__title">{t('feature.vault_protection_title')}</h5>
              <p className="fb-regular feature-items__desc">{t('feature.vault_protection_desc')}</p>
            </div>
          </div>
          <div className="feature__content" data-aos="fade-up" data-aos-duration="500">
            <h2 className="feature__title">{t('feature.trusted_platform_title')}</h2>
            <p className="fb-regular feature__subtitle">{t('feature.trusted_platform_subtitle')}</p>
            <a href="#" className="btn btn-primary btn-pill feature__button">{t('feature.trade_now_button')}</a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureSection;
