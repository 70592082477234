import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const topCoins = [
  "Bitcoin (BTC)", "Walletpayin (WPI)", "Ethereum (ETH)", "Binance Coin (BNB)", "Solana (SOL)", "Cardano (ADA)",
  "XRP (XRP)", "Polkadot (DOT)", "Avalanche (AVAX)", "Terra (LUNA)", "Dogecoin (DOGE)",
  "Chainlink (LINK)", "Litecoin (LTC)", "Bitcoin Cash (BCH)", "Stellar (XLM)", "Cosmos (ATOM)",
  "Polygon (MATIC)", "Tezos (XTZ)", "Algorand (ALGO)", "Filecoin (FIL)", "TRON (TRX)",
  "NEO (NEO)", "Ethereum Classic (ETC)", "VeChain (VET)", "Bitcoin SV (BSV)", "Fantom (FTM)",
  "Kusama (KSM)", "Elrond (EGLD)", "NEAR Protocol (NEAR)", "Huobi Token (HT)", "Harmony (ONE)",
  "Dash (DASH)", "The Graph (GRT)", "Zcash (ZEC)", "SushiSwap (SUSHI)", "Theta Network (THETA)",
  "Decred (DCR)", "Chiliz (CHZ)", "Crypto.com Coin (CRO)", "Celo (CELO)", "Hedera Hashgraph (HBAR)",
  "Enjin Coin (ENJ)", "Siacoin (SC)", "Nexo (NEXO)", "Flow (FLOW)", "Waves (WAVES)"
];

const environmentalOptions = [
  "Mobile app",
  "Website",
  "N/A",
];

const issueTypes = [
  "Buy/Sell Crypto",
  "Others",
  "Swap",
  "Partnership",
  "Affiliate program",
  "Ambassador program",
  "Set/Restore/Configure Wallet",
  "Staking",
  "Transaction",
  "Wallet Balance",
];

function Contact() {
  const { t } = useTranslation();
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    email: "",
    subject: "",
    environmental: "",
    coinToken: "",
    issueType: "",
    description: "",
    file: null,
    supportId: "",
    metamaskAddress: "",
    gdprChecked: false,
    signature: null
  });
  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size > 700 * 1024) {
      setMessage("The file is too large. Maximum size is 700 KB.");
      setMessageType('danger');
      setTimeout(() => {
        setMessage(null);
        setMessageType(null);
      }, 2000);
      e.target.value = null;
    } else {
      setFormData({
        ...formData,
        file: file
      });
    }
  }

  const connectMetamask = async () => {
    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const metamaskAddress = accounts[0];
        const supportId = metamaskAddress.slice(24, 32);
        setFormData({
          ...formData,
          metamaskAddress,
          supportId
        });
      } catch (error) {
        console.error(error);
      }
    }
  }

  const signMessage = async () => {
    if (window.ethereum && formData.metamaskAddress) {
      try {
        const signature = await window.ethereum.request({
          method: 'personal_sign',
          params: [
            window.ethereum.selectedAddress,
            `Do you want to continue sending a message to the Walletpayin support team? Then you need to sign a support ID: ${formData.supportId}`
          ]
        });

        return signature;
      } catch (error) {
        console.error(error);
        return null;
      }
    }

    return null;
  }

  const sendEmail = async (data) => {
    try {
      const response = await fetch('http://localhost:3010/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error('Error sending email:', error);
      return false;
    }
  };

  const handleConfirm = async () => {
    const signature = await signMessage();
    if (signature) {
      setFormData({
        ...formData,
        signature: signature
      });
      const emailSent = await sendEmail(formData);

      if (emailSent) {
        setMessage("Successfully sent!");
        setMessageType('success');
        setTimeout(() => {
          setMessage(null);
          setMessageType(null);
        }, 1600);
      } else {
        setMessage("Sending failed. Please try again.");
        setMessageType('danger');
        setTimeout(() => {
          setMessage(null);
          setMessageType(null);
        }, 2000);
      }

      setFormData({
        email: "",
        subject: "",
        environmental: "",
        coinToken: "",
        issueType: "",
        description: "",
        file: null,
        metamaskAddress: "",
        gdprChecked: false,
        supportId: "",
        signature: null
      });

      setStep(1);

      setTimeout(() => {
        setMessage(null);
      }, 3000);
    } else {
      setMessage("Error signing the message. Please try again.");
      setMessageType('danger');
      setTimeout(() => {
        setMessage(null);
        setMessageType(null);
      }, 2000);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.gdprChecked) {
      setMessage("Please accept GDPR terms.");
      setMessageType('danger');
      setTimeout(() => {
        setMessage(null);
        setMessageType(null);
      }, 2000);
      return null;
    }

    if (step === 1) {
      setFormData({
        ...formData,
      });
      await connectMetamask();
      setStep(2);
    } else if (step === 2) {
      if (!formData.metamaskAddress) {
        setMessage("Please connect your Metamask wallet before submitting the form.");
        setMessageType('danger');
        return;
      }

      const confirmed = window.confirm("Do you want to send the support message?");
      if (!confirmed) {
        return;
      }

      handleConfirm();
    }
  }

  return (
    <section className="contact">
      <div className="container d-flex flex-column align-items-center justify-content-center min-vh-100 mt-5">
        <h1 className="ls-tight font-bolder display-3 mb-3 text-center">{t('support.form.titles')}</h1>
        {message && (
          <p className={`alert alert-${messageType} mt-3`} role="alert">
            {message}
          </p>
        )}
        <div className="col-md-8">
          <form onSubmit={handleSubmit}>
            {step === 1 && (
              <div>
                <div className="form-outline mb-4 d-flex flex-column">
                  <label className="form-label text-left text-white" htmlFor="email">{t('support.form.email')}*</label>
                  <input
                    type="email"
                    id="email"
                    className="form-control"
                    required
                    value={formData.email}
                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  />
                </div>
                <div className="form-outline mb-4 d-flex flex-column">
                  <label className="form-label text-left text-white" htmlFor="subject">{t('support.form.subject')}*</label>
                  <input
                    type="text"
                    id="subject"
                    className="form-control"
                    required
                    value={formData.subject}
                    onChange={(e) => setFormData({ ...formData, subject: e.target.value })}
                  />
                </div>
                <div className="form-outline mb-4 d-flex flex-column">
                  <label className="form-label text-left text-white" htmlFor="environmental">{t('support.form.environmental')}*</label>
                  <select
                    id="environmental"
                    className="form-select"
                    required
                    value={formData.environmental}
                    onChange={(e) => setFormData({ ...formData, environmental: e.target.value })}
                  >
                    <option value="">{t('support.form.select.environmental')}</option>
                    {environmentalOptions.map((option, index) => (
                      <option key={index} value={option}>{option}</option>
                    ))}
                  </select>
                </div>
                <div className="form-outline mb-4 d-flex flex-column">
                  <label className="form-label text-left text-white" htmlFor="coinToken">{t('support.form.token')}</label>
                  <select
                    id="coinToken"
                    className="form-select"
                    value={formData.coinToken}
                    onChange={(e) => setFormData({ ...formData, coinToken: e.target.value })}
                  >
                    <option value="">{t('support.form.select.token')}</option>
                    {topCoins.map((coin, index) => (
                      <option key={index} value={coin}>{coin}</option>
                    ))}
                  </select>
                </div>
                <div className="form-outline mb-4 d-flex flex-column">
                  <label className="form-label text-left text-white" htmlFor="issueType">{t('support.form.issuetype')}*</label>
                  <select
                    id="issueType"
                    className="form-select"
                    required
                    value={formData.issueType}
                    onChange={(e) => setFormData({ ...formData, issueType: e.target.value })}
                  >
                    <option value="">{t('support.form.select.issuetype')}</option>
                    {issueTypes.map((type, index) => (
                      <option key={index} value={type}>{type}</option>
                    ))}
                  </select>
                </div>
                <div className="form-outline mb-4 d-flex flex-column">
                  <label className="form-label text-left text-white" htmlFor="description">{t('support.form.description')}*</label>
                  <textarea
                    className="form-control"
                    id="description"
                    rows="4"
                    required
                    value={formData.description}
                    onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                  ></textarea>
                </div>
                <div className="form-outline mb-4 d-flex flex-column">
                  <label className="form-label text-left text-white" htmlFor="file">{t('support.form.attach')}</label>
                  <input
                    type="file"
                    id="file"
                    accept=".pdf, .doc, .docx"
                    onChange={handleFileChange}
                  />
                </div>
                <div className="form-check mb-4 d-flex">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="gdprCheck"
                    checked={formData.gdprChecked}
                    onChange={() => setFormData({ ...formData, gdprChecked: !formData.gdprChecked })}
                  />
                  <label className="form-check-label text-white" htmlFor="gdprCheck">
                    {t('support.form.gdpr')} <a href="https://walletpayin.com/terms" target="_blank" rel="noopener noreferrer">{t('support.form.terms')}</a>.
                  </label>
                </div>
                <div className="d-flex mb-4">
                  <button type="submit" className="btn btn-primary">{t('support.form.next')}</button>
                </div>
              </div>
            )}
            {step === 2 && (
              <div className="container-lg max-w-screen-xl">
                <div className="col-md-8">
                  <div className="form-outline mb-4 d-flex flex-column py-10">
                    <p><b>{t('support.form.supportid')} {formData.supportId}</b></p>
                    <p>{formData.metamaskAddress}</p>
                    <h3 className="py-10">{t('support.form.recapitulation')}</h3>
                    <p>{t('support.form.mail')} {formData.email}</p>
                    <p>{t('support.form.subjectnow')} {formData.subject}</p>
                    <p>{t('support.form.environmental')} {formData.environmental}</p>
                    <p>{t('support.form.coin')} {formData.coinToken}</p>
                    <p>{t('support.form.issuetype')} {formData.issueType}</p>
                    <p>{t('support.form.description')} {formData.description}</p>
                    <div className="d-flex mb-4 py-10">
                      <button type="button" className="btn btn-primary" onClick={handleConfirm}>{t('support.form.submit')}</button>
                      <button type="button" className="btn btn-secondary ml-4" onClick={() => setStep(1)}>{t('support.form.edit')}</button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </section>
  );
}

export default Contact;
